
import { Component, Prop, Vue, PropSync } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  @Prop([String]) private value!: string;
  // 初始数据可以直接声明为实例的 property
  @PropSync("phone") formPhone !: string;
  @PropSync("staffType") formStaffType !: string;

  staffTypeOptions = [
    { label: "系统管理员", value: "1" },
    { label: "门店管理员", value: "2" },
    { label: "店员", value: "3" },
    { label: "服务商负责人", value: "4" },
  ]
}
